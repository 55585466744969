.contact-container {
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.contact-title {
  font-size: 48px;
  line-height: 52px;
}

.flex {
  display: flex;
}
