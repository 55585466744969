.flex-column {
  display: flex;
  flex-direction: column;
}

.contact-form {
  width: 642px;
}

.contact-form label {
  font-size: 24px;
  line-height: 28px;
  padding-top: 25px;
}

.contact-form label input,
.contact-form label textarea {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  font-size: 24px;
  margin-top: 9px;
  padding: 16px;
  color: #b8b8b8;
}

.contact-form input[type="submit"] {
  width: 330px;
  height: 60px;
  background-color: #009fe9;
  border-radius: 15px;
  margin: 25px auto;
  color: white;
  font-size:22px;
}
.contact-form label input {
  height: 60px;
}
.contact-form textarea {
  height: 258px;
}

@media screen and (max-width: 792px) {
  .contact-form {
    width: 331px;
  }

  .contact-form input[type="submit"] {
    width: 331px;
  }
}