.main-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 101px;
  align-items: center;
  padding: 2rem;
  max-width: 100vw;
}

.main-header-title {
  font-size: 20px;
  font-weight: 700;
  color: white;
  width: 130px;
  margin-left: 20px;
}

@media screen and (max-width: 792px) {
  .main-header {
    flex-direction: row-reverse;
  }

.main-header-title {
  display: none;
}
}
