.home-container {
	justify-content: space-around;
	flex: 1;
}

.home-info-container {
	margin: 5% 0;
	flex-direction: column;
	justify-content: space-between;
}

.mobile-btn {
	margin: 35px 0;
}

@media screen and (max-width: 792px) {
	.home-container {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.home-info-container {
		width: 70%;
		text-align: center;
	}

	.home-img-container img {
		width: 300px;
		height: 600px;
	}
}

@media screen and (min-width: 793px) {
	.mobile-btn {
		display: none;
	}
}
